<!--
 * @Author: your name
 * @Date: 2021-02-23 16:18:54
 * @LastEditTime: 2021-02-23 18:09:29
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \aiparkcity-acb\src\components\graphTitle\graphTitle.vue
-->
<template>
  <div>
  	<div class="title">
			<div class="title_icon" v-show="hasIcon">
			</div>
			<span style="padding-right:10px">{{title}}</span>
			
			<slot></slot>
		</div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },  
  props: {
  	title: {
  		default: ''
  	},
  	hasIcon: {
  		default: true
  	}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
$baseColor = #0c66ff;
.title
	font-size: 14px;
	color: #2f2f51;
	height: 20px;
	display flex
	align-items center
	.title_icon
		width: 4px;
		height: 15px;
		background-color: $baseColor;
		margin-right: 10px;
</style>

