var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "title" },
      [
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasIcon,
              expression: "hasIcon",
            },
          ],
          staticClass: "title_icon",
        }),
        _c("span", { staticStyle: { "padding-right": "10px" } }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
        _vm._t("default"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }